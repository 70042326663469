import * as analytics from '@90poe/analytics';
import { type AuthTokenData } from '@90poe/auth';
import decodeJwt from 'jwt-decode';
import { getSubdomain } from '../../helpers/getSubdomain';

const emailDomainRegex = /^[^@\s]+@([^@\s.]*)/;

type UserProfileProps = {
    email?: string;
    nickname?: string;
    userId?: string;
};

export type AnalyticsConfig = {
    analyticsAppId?: string;
    getServiceTokenFunc: () => string;
};

const getEmailDomain: (email?: string) => string = (email = '') => {
    const result = emailDomainRegex.exec(email);
    return result ? result[1] : '';
};

const updateAnalyticsProfile: (
    userProfile: UserProfileProps,
    getServiceTokenFunc: () => string,
) => void = ({ email, nickname, userId }, getServiceTokenFunc) => {
    const tenantSubdomain = getSubdomain();

    const { user_extra_type: accountExtraType, account: tenantAccount } =
        decodeJwt<{
            user_extra_type: string;
            account: string;
        }>(getServiceTokenFunc());

    const emailDomain = getEmailDomain(email);
    const uniqueIdentity = email || nickname || userId;

    if (!uniqueIdentity) return;

    analytics.identify(uniqueIdentity);

    analytics.updateProfile({
        'Tenant ID': tenantAccount,
        'Tenant Name': tenantSubdomain,
        'User Type': accountExtraType,
        emailDomain,
    });
};

const initialiseAnalytics = (analyticsAppId?: string, e2e?: boolean): void => {
    if (analyticsAppId && !e2e) {
        analytics.initialise(analyticsAppId);
    }
};

export const initAnalytics: (
    token: AuthTokenData,
    config: AnalyticsConfig,
) => void = (token, config) => {
    const { email, nickname, userId, e2e } = token;

    initialiseAnalytics(config.analyticsAppId, e2e);
    updateAnalyticsProfile(
        {
            email,
            nickname,
            userId,
        },
        config.getServiceTokenFunc,
    );
};
